import cn from 'classnames'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { subMenuArrowOffsetState } from '../src/store/subMenuArrowOffsetState'
import { subMenuDetailOpenState } from '../src/store/subMenuDetailOpenState'
import { isSpecialKeyPressed } from '../src/utils/isSpecialKeyPressed'
import { FlexiLink } from './FlexiLink'
import { SubMenuDetail } from './SubMenuDetail'
import { ProductNavigationItemFragment } from '../src/model/fragments'
import util from 'util';

export interface SubMenuDesktopProps {
	items: ProductNavigationItemFragment[]
}

export const SubMenuDesktop: React.FunctionComponent<SubMenuDesktopProps> = ({
	items,
}) => {

	const arrowOffset = useRecoilValue(subMenuArrowOffsetState)
	const [isSubMenuDetailOpen, setIsSubMenuDetailOpen] = useRecoilState(
		subMenuDetailOpenState,
	)
	const [detail, setDetail] = React.useState<null | number>(null)
	const hoverTimeoutRef = React.useRef<number>()
	const [isKeepOpenForced, setIsKeepOpenForced] = React.useState(false)
	const keepOpenForcedTimeoutRef = React.useRef<number>()

	return (
		<nav
			className={cn('subMenuDesktop', isSubMenuDetailOpen && 'is-detailOpen')}
			style={{
				['--subMenuDesktop-arrow-offset' as any]:
					arrowOffset > 0 ? `${arrowOffset}px` : undefined,
			}}
		>
			<div className="subMenuDesktop-band">
				<div className="subMenuDesktop-band-in">
					{items.map((item, i) => (
						<FlexiLink key={i} {...(item.link as any)}>
							<a
								className={cn(
									'subMenuDesktop-band-item',
									detail === i && isSubMenuDetailOpen && 'is-active',
								)}
								onClick={(event) => {
									if (!isSpecialKeyPressed(event)) {
										event.preventDefault()
										clearTimeout(hoverTimeoutRef.current)
										setIsSubMenuDetailOpen(
											isKeepOpenForced || detail !== i || !isSubMenuDetailOpen,
										)
										setDetail(i)
									}
								}}
								onMouseEnter={() => {
									if (isSubMenuDetailOpen && detail === i) {
										return
									}

									clearTimeout(hoverTimeoutRef.current)
									hoverTimeoutRef.current = window.setTimeout(() => {
										setIsSubMenuDetailOpen(true)
										setDetail(i)
										setIsKeepOpenForced(true)
										clearTimeout(keepOpenForcedTimeoutRef.current)
										keepOpenForcedTimeoutRef.current = window.setTimeout(() => {
											setIsKeepOpenForced(false)
										}, 750)
									}, 200)
								}}
								onMouseLeave={() => {
									clearTimeout(hoverTimeoutRef.current)
								}}
								data-content={item.caption}
							>
								{item.caption}
							</a>
						</FlexiLink>
					))}
				</div>
			</div>
			<div className="subMenuDesktop-detail">
				{detail !== null && <SubMenuDetail detail={items[detail]} />}
			</div>
		</nav>
	)
}
