import cn from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import { useTranslate } from '../src/hooks/useTranslate'
import SvgSearch from './icons/SvgSearch'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	multiline?: boolean
	label?: string
	submitStyle?: 'bland' | 'striking'
	submitIcon?: React.ReactNode
	small?: boolean
	micro?: boolean
	condense?: boolean
	onChange?: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void
	align?: 'left' | 'right' | 'center'
}

export const Input = React.forwardRef<
	HTMLTextAreaElement & HTMLInputElement,
	InputProps
>(
	(
		{
			label,
			multiline,
			submitStyle,
			submitIcon = <SvgSearch />,
			small,
			micro,
			condense,
			align = 'left',
			autoFocus,
			onChange,
			defaultValue,
			...inputProps
		},
		ref,
		) => { 

			const translate = useTranslate();

			return (
			<label
				className={cn(
					'input',
					multiline && 'view-multiline',
					submitStyle && 'view-submit',
					submitStyle && `view-submit-${submitStyle}`,
					small && 'view-small',
					micro && 'view-micro',
					condense && 'view-condense',
					`view-align-${align}`,
				)}
			>
				{multiline ? (
					<textarea
						ref={ref}
						className="input-control"
						name={inputProps.name}
						autoComplete={inputProps.autoComplete}
						required={inputProps.required}
						placeholder={label}
						onChange={onChange}
						defaultValue={defaultValue}
					/>
				) : (
					<>
						<input
							ref={ref}
							className="input-control"
							placeholder={label}
							onChange={onChange}
							defaultValue={defaultValue}
							{...inputProps}
						/>
						{submitStyle && (
							<button
								type="submit"
								className="input-submit"
								aria-label={translate('search')}
							>
								{submitIcon}
							</button>
						)}
					</>
				)}
				{label && (
					<div className="input-label">
						<span className="input-label-in">{label}</span>
					</div>
				)}
			</label>
		)
	}
)
