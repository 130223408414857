import { ProductPriceRegion } from './currency';
import { Locale } from './locale'
export { ProductPriceRegion }

export const getRegionsForLocale = (locale: Locale) => {
	if (locale === Locale.sk) {
		return [ProductPriceRegion.SK];
	}
	
	return [ProductPriceRegion.CZ];
}
