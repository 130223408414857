import * as React from 'react'

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="1.47em" height="1.47em" viewBox="0 0 22 22" {...props}>
			<path
				fill="currentColor"
				d="M2.513 8.169A5.648 5.648 0 018.17 2.513a5.648 5.648 0 015.655 5.656 5.648 5.648 0 01-5.655 5.655 5.648 5.648 0 01-5.656-5.655zm12.203 5.655l-.352-.34a8.132 8.132 0 001.973-5.315A8.168 8.168 0 008.17 0a8.168 8.168 0 100 16.336 8.13 8.13 0 005.315-1.973l.34.353v.993l6.283 6.27 1.873-1.872-6.271-6.283z"
			/>
		</svg>
	)
}

export default SvgSearch
