import { Locale } from './locale'

export enum Currency {
	CZK = 'CZK',
	EUR = 'EUR',
}

export enum ProductPriceRegion {
	WEST = 'west',
	EAST = 'east',
	CZ = 'cz',
	SK = 'sk'
}

export const getCurrencyByLocale = (locale: Locale) => {
	if (locale === Locale.sk) {
		return Currency.EUR
	}
	return Currency.CZK
}
