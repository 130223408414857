import { useRouter } from 'next/router'
import { parse, ParsedUrlQueryInput, stringify } from 'querystring'
import { UrlObject } from 'url'
import { LinkableFragment, SpecialPageType } from './fragments'
import { getLocale, getLocaleUrlPrefix, getLocalisedUrlHost, Locale } from './locale'

export const pathnames = {
	product: '/product',
	productCategory: '/productCategory',
	productUsage: '/productUsage',
	blogArticle: '/blogArticle',
	blogCategory: '/blogCategory',
	place: '/place',
	referenceCategory: '/referenceCategory',
	helpQuestion: '/helpQuestion',
	representativeCategory: '/representativeCategory',
	video: '/video'
}

export const getBlogArticleLink = (
	locale: Locale,
	id: string,
	url: string,
) => ({
	href: { pathname: pathnames.blogArticle, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getBlogCategoryLink = (
	locale: Locale,
	id: string,
	url: string,
) => ({
	href: { pathname: pathnames.blogCategory, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getRepresentativeCategoryLink = (
	locale: Locale,
	id: string,
	url: string,
) => ({
	href: { pathname: pathnames.representativeCategory, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getPageLink = (
	locale: Locale,
	id: string,
	url: string,
	specialType: string | null,
	forceLocale = false
) => { 

	const prefix = forceLocale ? getLocalisedUrlHost(locale) : getLocaleUrlPrefix(locale);
	return ({
		href: {
			pathname: specialType ? `/${specialType}` : '/page',
			query: { id, locale },
		},
		as: prefix + url,
	})
}

export const getProductLink = (locale: Locale, id: string, url: string) => ({
	href: { pathname: pathnames.product, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getProductCategoryLink = (
	locale: Locale,
	id: string,
	url: string,
) => {
	return {
		href: { pathname: pathnames.productCategory, query: { id, locale } },
		as: getLocaleUrlPrefix(locale) + url,
	}
}

export const getPlaceLink = (locale: Locale, id: string, url: string) => ({
	href: { pathname: pathnames.place, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getVideoLink = (locale: Locale, id: string, url: string) => ({
	href: { pathname: pathnames.video, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getReferenceCategoryLink = (
	locale: Locale,
	id: string,
	url: string,
) => ({
	href: { pathname: pathnames.referenceCategory, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getProductUsageLink = (
	locale: Locale,
	id: string,
	url: string,
) => ({
	href: { pathname: pathnames.productUsage, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

export const getHelpQuestionLink = (
	locale: Locale,
	id: string,
	url: string,
) => ({
	href: { pathname: pathnames.helpQuestion, query: { id, locale } },
	as: getLocaleUrlPrefix(locale) + url,
})

function createBaseLink(
	linkable: Omit<LinkableFragment, 'redirect'> & {
		redirect?: LinkableFragment['redirect']
	},
	locale: Locale,
): Link {

	if(linkable)
	{
		if (linkable.redirect && linkable.redirect.target) {
			// @TODO: handle chained redirects
			return createBaseLink(linkable.redirect.target, locale)
		} else if (linkable.page) {
			return getPageLink(
				locale,
				linkable.page.id,
				linkable.url,
				Object.values(SpecialPageType).includes(linkable.page.specialPage) ? (linkable.page.specialPage || null) : null,
			)
		} else if (linkable.blogArticle) {
			return getBlogArticleLink(locale, linkable.blogArticle.id, linkable.url)
		} else if (linkable.blogCategory) {
			return getBlogCategoryLink(locale, linkable.blogCategory.id, linkable.url)
		} else if (linkable.product) {
			return getProductLink(locale, linkable.product.id, linkable.url)
		} else if (linkable.productCategory) {
			return getProductCategoryLink(
				locale,
				linkable.productCategory.id,
				linkable.url,
			)
		} else if (linkable.referenceCategory) {
			return getReferenceCategoryLink(
				locale,
				linkable.referenceCategory.id,
				linkable.url,
			)
		} else if (linkable.productUsage) {
			return getProductUsageLink(locale, linkable.productUsage.id, linkable.url)
		} else if (linkable.place) {
			return getPlaceLink(locale, linkable.place.id, linkable.url)
		} else if (linkable.helpQuestion) {
			return getHelpQuestionLink(locale, linkable.helpQuestion.id, linkable.url)
		}
	}
	
	console.log('ERROR LINKABLE');
	console.log(linkable);
	throw new Error('Link not implemented.')
}

export const getLink = (
	locale: Locale,
	linkable: LinkableFragment,
	query?: string,
) => {
	const link = createBaseLink(linkable, locale)
	if (!query) {
		return link
	}

	const [q, h] = query.split('#');
	const linkWithQuery = getLinkWithQuery(link, parse(q))
	
	if(h)
	{
		linkWithQuery.href.hash = '#' + h;
		linkWithQuery.as = linkWithQuery.as + '#' + h;
	}
	
	return linkWithQuery;
}

export const linkToAbsoluteUrl = (link: ReturnType<typeof getLink>) => {
	// @TODO: make it work on server, generate full url on server
	return `${'location' in globalThis ? location.origin : ''}${link.as}`
}

export const useIsLinkCurrent = (link: ReturnType<typeof getLink> | null) => {
	const router = useRouter()

	return link !== null && router.asPath === link.as
}

export interface Link {
	as: string
	href: Omit<UrlObject, 'query'> & { query?: ParsedUrlQueryInput }
}

export const getLinkWithQuery = (
	link: Link,
	query: ParsedUrlQueryInput,
): Link => {
	const qIndex = link.as.indexOf('?')
	const currentQuery = qIndex >= 0 ? parse(link.as.substr(qIndex + 1)) : {}
	const newQuery = Object.fromEntries(
		Object.entries({ ...currentQuery, ...query }).filter(
			([, val]) => val !== undefined && val !== null,
		),
	)
	const newQueryString = stringify(newQuery)
	const asPathname = qIndex >= 0 ? link.as.substring(0, qIndex) : link.as
	const linkAs =
		asPathname + (newQueryString.length > 0 ? '?' + newQueryString : '')

	return {
		href: {
			...link.href,
			query: {
				...link.href.query,
				...query,
			}
		},
		as: linkAs,
	}
}
