import React from 'react'
import { LayoutFragment } from '../model/fragments'

type LayoutStateProps = LayoutFragment | undefined

const defaultValue: LayoutStateProps = undefined

const LayoutContext = React.createContext<LayoutStateProps>(defaultValue)

export const LayoutContextProvider: React.FunctionComponent<{
	data: LayoutStateProps
}> = ({ children, data }) => (
	<LayoutContext.Provider value={data}>{children}</LayoutContext.Provider>
)

export const useLayoutData = () => React.useContext(LayoutContext)
