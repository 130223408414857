import { atom, useRecoilState, useRecoilValue } from 'recoil'

type Variants = {
	[variantId: string]: number
}

type Compositions = {
	[compositionId: string]: Variants
}

export interface ShoppingListData {
	variants: Variants
	compositions: Compositions
}

const LOCAL_STORAGE_VARIANTS_KEY = 'shopping-list-variants'
const LOCAL_STORAGE_COMPOSITIONS_KEY = 'shopping-list-compositions'

const defaultValue: ShoppingListData = (() => {
	const load = (key: string) => {
		try {
			const data = JSON.parse(
				('localStorage' in globalThis && localStorage.getItem(key)) || '{}',
			)
			return data
		} catch (error) {
			console.error(error)
		}
		return {}
	}

	const variants = load(LOCAL_STORAGE_VARIANTS_KEY)
	const compositions = load(LOCAL_STORAGE_COMPOSITIONS_KEY)

	return { variants, compositions }
})()

export const shoppingListState = atom<ShoppingListData>({
	key: 'shoppingList',
	default: defaultValue,
})

export const PersistShoppingList: React.FunctionComponent = () => {
	// @TODO: fix state is persisted twice on page load
	const shoppingList = useRecoilValue(shoppingListState)
	if ('localStorage' in globalThis) {
		localStorage.setItem(
			LOCAL_STORAGE_VARIANTS_KEY,
			JSON.stringify(shoppingList.variants),
		)
		localStorage.setItem(
			LOCAL_STORAGE_COMPOSITIONS_KEY,
			JSON.stringify(shoppingList.compositions),
		)
	}
	return null
}

const adjustVariant = (
	variants: Variants,
	variantId: string,
	quantity: number,
) => {
	const result = { ...variants }
	if (!(variantId in result)) {
		result[variantId] = 0
	}

	result[variantId] += quantity

	if (result[variantId] <= 0) {
		delete result[variantId]
	}

	return result
}

export const useAdjustVariantInShoppingList = () => {
	const [shoppingList, set] = useRecoilState(shoppingListState)

	
	return (variantId: string, quantity: number) => {
		set({
			...shoppingList,
			variants: Object.fromEntries(Object.entries(adjustVariant(shoppingList.variants, variantId, quantity)).filter(e => e[0] && e[1])),
		})
	}
}

export const useAdjustMultipleVariantInShoppingList = () => {
	const [shoppingList, set] = useRecoilState(shoppingListState)

	return (items: Array<{ variantId: string; quantity: number }>) => {
		let result = shoppingList
		items.forEach((item) => {
			result = {
				...result,
				variants: Object.fromEntries(Object.entries(adjustVariant(result.variants, item.variantId, item.quantity)).filter(e => e[0] && e[1])),
			}
		})
		set(result)
	}
}
