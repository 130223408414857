import React from 'react'

// @TODO: ignore if form element focused

export function useOnEscapeKey(callback: () => void, enabled: boolean = true) {
	React.useEffect(() => {
		if (enabled) {
			const onKey = (event: KeyboardEvent) => {
				if (event.key === 'Escape') {
					callback()
				}
			}
			window.addEventListener('keydown', onKey)
			return () => window.removeEventListener('keydown', onKey)
		}
	}, [callback, enabled])
}
