import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import React from 'react'
import { RecoilRoot } from 'recoil'
import { FacebookPixelClientOnly } from '../components/FacebookPixelClientOnly'
import { CookieConsent } from '../components/CookieConsent'
import { GoogleAnalytics } from '../components/GoogleAnalytics'
import { Layout } from '../components/Layout'
import { ModalManager } from '../components/ModalManager'
import { Seo } from '../components/Seo'
import { CurrentLocaleContextProvider } from '../src/contexts/CurrentLanguageContext'
import { LayoutContextProvider } from '../src/contexts/LayoutContext'
import { PagesContextProvider } from '../src/contexts/PagesContext'
import { TranslationsContextProvider } from '../src/contexts/TranslationsContext'
import {
	LayoutFragment,
	PageWithUrlFragment,
	TranslationDomainFragment,
} from '../src/model/fragments'
import { DEFAULT_LOCALE, getPossibleLocales, Locale } from '../src/model/locale'
import { PersistShoppingList } from '../src/store/shoppingListState'
import { UnwrapPromise } from '../src/utils/unwrapPromise'
import '../styles/main.sass'
import { axiosGet } from '../src/utils/axiosWrapper'
import { FullscreenContextProvider } from '../src/contexts/FullscreenContext'

function MyApp({
	Component,
	pageProps,
	...otherProps
}: AppProps & UnwrapPromise<ReturnType<typeof getInitialProps>>) {
	const page = <Component {...pageProps} />

	return (

		<RecoilRoot>
			{  process.env.NODE_ENV == 'production' &&
				<React.Fragment>
					<GoogleAnalytics locale={otherProps.locale} />
				</React.Fragment>	
			}
			<FacebookPixelClientOnly />
			<CurrentLocaleContextProvider currentLocale={otherProps.locale}>
				<TranslationsContextProvider data={otherProps.appTranslations}>
					<LayoutContextProvider data={otherProps.layout}>
						<FullscreenContextProvider>
							<PagesContextProvider data={otherProps.pages}>
								<CookieConsent />
								<PersistShoppingList />
								<Seo seo={undefined} />
								<Head>
									<meta name="theme-color" content="#299b49" />
									<link rel="preconnect" href="https://tools.luckyorange.com" />
									<link rel="preconnect" href="https://www.google-analytics.com" />
									<link rel="preconnect" href="https://googleads.g.doubleclick.net" />
									<link rel="preconnect" href="https://s2.adform.net" />
									<link rel="preconnect" href="https://track.adform.net" />
									<link
										rel="preload"
										href="/fonts/Avenir-Black.ttf"
										as="font"
										crossOrigin="anonymous"
									/>
									<link
										rel="preload"
										href="/fonts/Avenir-Book.ttf"
										as="font"
										crossOrigin="anonymous"
									/>
									<link
										rel="preload"
										href="/fonts/Avenir-Heavy.ttf"
										as="font"
										crossOrigin="anonymous"
									/>
									<link
										rel="preload"
										href="/fonts/Avenir-Medium.ttf"
										as="font"
										crossOrigin="anonymous"
									/>
									<link
										rel="preload"
										href="/fonts/Avenir-Oblique.ttf"
										as="font"
										crossOrigin="anonymous"
									/>
									<link
										rel="preload"
										href="/fonts/DIN-Condensed-Bold.ttf"
										as="font"
										crossOrigin="anonymous"
									/>
									<link
										rel="search"
										href="/api/search.xml"
										type="application/opensearchdescription+xml"
										title="Presbeton"
									/>
									<script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
										console.log(new Date().getTime());
										window.addEventListener('DOMContentLoaded', () => {console.log('DOM fully loaded and parsed');});
										window.document.addEventListener('ready', function(){console.log('Document ready: ' + (new Date().getTime()))});`
									}} ></script>
								</Head>
								<Layout>
									<ModalManager
										page={page}
										isModal={pageProps.modal || false}
										width={pageProps.modalWidth}
									/>
								</Layout>
							</PagesContextProvider>
						</FullscreenContextProvider>
					</LayoutContextProvider>
				</TranslationsContextProvider>
			</CurrentLocaleContextProvider>
		</RecoilRoot>
	)
}

const getInitialProps = async (ctx: AppContext) => {
	
	const locale = (ctx.ctx.query.locale || DEFAULT_LOCALE) as Locale
	const possibleLocales = getPossibleLocales(locale)

	const {
		getLayout: layout,
		listPage: pages,
		getTranslationDomain: appTranslations,
	} = await axiosGet('/layout', {locale: locale}) as {
		getLayout: LayoutFragment,
		listPage: PageWithUrlFragment[],
		getTranslationDomain: TranslationDomainFragment
	};

	const appProps = await App.getInitialProps(ctx)
	return {
		...appProps,
		layout,
		pages,
		appTranslations,
		possibleLocales,
		locale,
	}
}

MyApp.getInitialProps = getInitialProps

export default MyApp
