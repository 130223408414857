import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import cn from 'classnames'
import { useRouter } from 'next/router'
import * as React from 'react'
import Modal from 'react-modal'

// Modal.setAppElement('#__layout')

const IsInModalContext = React.createContext(false)
const HasModalContext = React.createContext(false)

export const useIsInModal = () => {
	return React.useContext(IsInModalContext)
}
export const useHasModal = () => {
	return React.useContext(HasModalContext)
}

export const useIsModalOpen = () => {
	return React.useContext(HasModalContext)
}

export const ModalContent: React.FunctionComponent = ({ children }) => {
	const isOpen = useIsModalOpen()
	const ref = React.useRef<HTMLDivElement>(null)

	React.useEffect(() => {
		const element = ref.current
		if (isOpen && element) {
			disableBodyScroll(element, {
				reserveScrollBarGap: true,
			})

			return () => {
				enableBodyScroll(element)
			}
		}
	}, [isOpen])

	return (
		<div className="modal-content" ref={ref}>
			{children}
		</div>
	)
}

export const ModalManager = ({
	page,
	isModal,
	width = 'normal',
}: {
	page: React.ReactElement
	isModal: boolean
	width: 'narrow' | 'normal'
}) => {
	const router = useRouter()
	const [mainPage, setMainPage] = React.useState(page)
	const [modal, setModal] = React.useState<React.ReactElement>()
	const [isOpen, setIsOpen] = React.useState(false)

	React.useEffect(() => {
		if (!isModal) {
			setMainPage(page)
			setModal(undefined)
		} else if (page !== mainPage) {
			setModal(page)
		}
	}, [mainPage, page, isModal])

	React.useEffect(() => {
		setIsOpen(modal !== undefined)
	}, [modal])

	const close = React.useCallback(() => {
		setIsOpen(false)
		router.back()
	}, [router])

	return (
		<HasModalContext.Provider value={isOpen}>
			<Modal
				isOpen={isOpen}
				onRequestClose={close}
				className={cn('modal', `view-width-${width}`)}
				overlayClassName="modal-overlay"
			>
				<button
					className="modal-close"
					type="button"
					onClick={close}
					aria-label="Zavřít"
				/>
				<IsInModalContext.Provider value={true}>
					{modal}
				</IsInModalContext.Provider>
			</Modal>
			{mainPage}
		</HasModalContext.Provider>
	)
}
