import Link from 'next/link'
import React from 'react'
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext'
import { LinkableFragment, LinkType } from '../src/model/fragments'
import { getLink } from '../src/model/urls'
import { assertNever } from '../src/utils/assertNever'

export type FlexiLinkProps = {
	openExternalLinkInSameTab?: boolean
} & (
	| {
			type: LinkType.exact
			internalLink: LinkableFragment
	}
	| {
			type: LinkType.external
			externalLink: string
	  }
	| {
			type: LinkType.internal
			internalLink: LinkableFragment
			internalLinkQuery?: string
	  }
	| {
			nextLink: ReturnType<typeof getLink>
	  }
)

export const FlexiLink: React.FunctionComponent<FlexiLinkProps> = ({
	children,
	openExternalLinkInSameTab,
	...props
}) => {
	const locale = useCurrentLocale()
	
	if ('type' in props && props.type === LinkType.external) {
		const child: any = React.Children.only(children)
		return React.cloneElement(child, {
			href: props.externalLink,
			target: openExternalLinkInSameTab ? undefined : '_blank',
			rel: openExternalLinkInSameTab ? undefined : 'noopener noreferrer',
		})
	} else if ('type' in props && props.type === LinkType.internal) {
		return (
			<Link {...getLink(locale, props.internalLink, props.internalLinkQuery)}>
				{children}
			</Link>
		)
	} else if ('type' in props && props.type === LinkType.exact) {
		return (
			<Link href={props.internalLink.url}>
				{children}
			</Link>
		)
	} else if ('nextLink' in props) {
		return <Link {...props.nextLink}>{children}</Link>
	} else {
		return (<></>);
		// return assertNever(props)
	}
}
