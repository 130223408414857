import Link from 'next/link'
import React from 'react'
import { SpecialPageType } from '../src/model/fragments'
import { usePageLink } from '../src/hooks/usePageLink'
import { useTranslate } from '../src/hooks/useTranslate'
import { Arrow } from './Arrow'
import SvgBook from './icons/SvgBook'
import SvgFaq from './icons/SvgFaq'

export const QuickLinks: React.FunctionComponent = () => {
	const helpLink = usePageLink(SpecialPageType.help)
	const blogLink = usePageLink(SpecialPageType.blog)

	const translate = useTranslate()

	return (
		<div className="quickLinks">
			<div className="quickLinks-in">
				{helpLink && (
					<Link {...helpLink}>
						<a className="quickLinks-item">
							<span className="quickLinks-item-icon">
								<SvgFaq />
							</span>
							{translate('quickLinks-help-title')}
							<Arrow />
						</a>
					</Link>
				)}
				{blogLink && (
					<Link {...blogLink}>
						<a className="quickLinks-item">
							<span className="quickLinks-item-icon">
								<SvgBook />
							</span>
							{translate('quickLinks-blog-title')}
							<Arrow />
						</a>
					</Link>
				)}
			</div>
		</div>
	)
}
