import cn from 'classnames'
import Link from 'next/link'
import React from 'react'
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext'
import { useOnAnyClick } from '../src/hooks/useOnAnyClick'
import { usePageLink } from '../src/hooks/usePageLink'
import { SpecialPageType } from '../src/model/fragments'
import { Locale } from '../src/model/locale'
import { assertNever } from '../src/utils/assertNever'
import { isSpecialKeyPressed } from '../src/utils/isSpecialKeyPressed'
import { ConditionalWrap } from './ConditionalWrap'
import SvgCzechia from './icons/SvgCzechia'
import SvgSlovakia from './icons/SvgSlovakia'

export interface LanguagePickerProps {}

export const LanguagePicker: React.FunctionComponent<LanguagePickerProps> =
	() => {
		const currentLocale = useCurrentLocale()
		const [isOpen, setIsOpen] = React.useState(false)

		const [links, setLinks] = React.useState([
			{
				locale: Locale.cs,
				link: usePageLink(SpecialPageType.homepage, Locale.cs)!,
				label: 'CZ',
			},
			{
				locale: Locale.sk,
				link: usePageLink(SpecialPageType.homepage, Locale.sk)!,
				label: 'SK'
			}
		])


		// React.useEffect(() => {
		// 	const sk: any = {
		// 		locale: Locale.sk,
		// 		link: skLink,
		// 			// 'location' in globalThis && location.host === 'presbeton.cz'
		// 			// 	? {
		// 			// 			// @TODO: později po spuštění linkovat i na novou sk varzi
		// 			// 			href: 'https://www.presbeton.sk/',
		// 			// 			as: 'https://www.presbeton.sk/',
		// 			// 	  }
		// 			// 	: skLink,
		// 		label: 'SK',
		// 	}
		// 	setLinks([links[0], sk])
		// 	// eslint-disable-next-line react-hooks/exhaustive-deps
		// }, [])

		// @TODO: link to current page
		/*const links = [
		{
			locale: Locale.cs,
			link: usePageLink(SpecialPageType.homepage, Locale.cs)!,
			label: 'CZ',
		},
		{
			locale: Locale.sk,
			link: usePageLink(SpecialPageType.homepage, Locale.sk)!,
			label: 'SK',
		},
	]*/

		const onClick = React.useCallback(() => {
			if (isOpen) {
				setIsOpen(false)
			}
		}, [isOpen])

		useOnAnyClick(onClick, isOpen)

		return (
			<div className={cn('languagePicker', isOpen && 'is-open')}>
				<div className="languagePicker-current">
					{currentLocale === Locale.cs ? (
						<SvgCzechia />
					) : currentLocale === Locale.sk ? (
						<SvgSlovakia />
					) : (
						assertNever(currentLocale)
					)}
				</div>
				<div className="languagePicker-options">
					{links
						.filter((link) => link.link !== null)
						.sort((a, _) => (a.locale === currentLocale ? -1 : 0))
						.map((link, i) => (
							<div className="languagePicker-option" key={link.locale}>
								<ConditionalWrap
									condition={i === 0}
									wrap={(children) => (
										<button
											onClick={() => {
												setIsOpen(!isOpen)
											}}
											type="button"
											className="languagePicker-opener"
										>
											{children}
										</button>
									)}
								>
									<ConditionalWrap
										condition={i !== 0}
										wrap={(children) => (
											<Link {...link.link}>
												<a
													className="languagePicker-link"
													onClick={(event) => {
														// @TODO: remove on click a let nextjs handle the routing - best case scenario full page reload shouldn't be needed
														if (!isSpecialKeyPressed(event)) {
															event.preventDefault()
															window.location.href = link.link.as
														}
													}}
												>
													{children}
												</a>
											</Link>
										)}
									>
										<>{link.label}</>
									</ConditionalWrap>
								</ConditionalWrap>
							</div>
						))}
				</div>
			</div>
		)
	}
