import cn from 'classnames'
import React from 'react'

export interface HamburgerButtonProps {
	isOpen: boolean
	onClick: () => void
}

export const HamburgerButton: React.FunctionComponent<HamburgerButtonProps> = ({
	children,
	isOpen,
	onClick,
}) => {
	return (
		<button
			type="button"
			className={cn('hamburgerButton', isOpen && 'is-open')}
			onClick={onClick}
		>
			<div className="hamburgerButton-lines">
				<span className="hamburgerButton-line"></span>
				<span className="hamburgerButton-line"></span>
				<span className="hamburgerButton-line"></span>
			</div>
			{children}
		</button>
	)
}
