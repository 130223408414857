import getNextConfig from 'next/config'

export const getConfig = <Key extends string>(
	...keys: Key[]
): { [K in Key]: string } => {
	const { serverRuntimeConfig, publicRuntimeConfig } = getNextConfig()
	const result: any = {}
	for (const key of keys) {
		const value = publicRuntimeConfig[key] ?? serverRuntimeConfig[key]
		if (value === undefined) {
			throw new Error(`Config key ${key} is not defined`)
		}
		result[key] = value
	}
	return result
}
