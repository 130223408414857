import { useMemo } from 'react'
import { useCurrentLocale } from '../contexts/CurrentLanguageContext'
import { Currency, getCurrencyByLocale } from '../model/currency'
import { ProductPriceFragment, ProductPriceRangeFragment, ProductUnit } from '../model/fragments'
import { getLocale, getPossibleLocales, Locale } from '../model/locale'
import { getRegionsForLocale, ProductPriceRegion } from '../model/region'
import { formatPrice, multiplyPrice } from '../utils/formatPrice'
import { assertDefined } from '../utils/isDefined'
import { productUnitToLabel } from '../utils/productUnitToLabel'
import { useCurrency } from './useCurrency'
import { useRegions } from './useRegions'
import { createTranslatorForLocale, Translator, useTranslate } from './useTranslate'

const vatMultipliers = {
	[Currency.CZK]: 1.21,
	[Currency.EUR]: 1.2,
}

export const regionMapping = {
	[ProductPriceRegion.CZ] : 'česko',
	[ProductPriceRegion.SK] : 'slovensko',
	[ProductPriceRegion.WEST] : 'západ',
	[ProductPriceRegion.EAST] : 'východ',
}

export class Localization {
	constructor(
		public readonly locale: Locale,
		public readonly possibleLocales: Locale[],
		public readonly translate: Translator,
		public readonly currency: Currency,
		public readonly regions: ProductPriceRegion[]
	) {}

	extractPrices<P extends ProductPriceFragment | ProductPriceRangeFragment>(prices: P[]): P[] {
		const ps = prices.filter((it) => it.currency === this.currency && this.regions.includes(it.region) )
		assertDefined(ps.length)
		return ps
	}

	applyPricesVAT<P extends ProductPriceFragment | ProductPriceRangeFragment>(prices: P[], vat: boolean) : P[] {
		return prices.map(price => {
			const vatMultiplier = vat ? vatMultipliers[price.currency] : 1
			return multiplyPrice(price, vatMultiplier)
		});
	}

	removeAppliedPricesVAT<P extends ProductPriceFragment | ProductPriceRangeFragment>(prices: P[], vat: boolean) : P[] {
		return prices.map(price => {
			const vatMultiplier = vat ? vatMultipliers[price.currency] : 1
			return multiplyPrice(price, 1.0 / vatMultiplier)
		});
	}

	formatPrice(price: ProductPriceFragment, productQuantity = 1, vat = false, region = true) {
		const vatMultiplier = vat ? vatMultipliers[price.currency] : 1
		let priceFormatted = formatPrice(price, productQuantity, vatMultiplier)

		if (price.unit) {
			const unit = price.unit as ProductUnit
			priceFormatted =
				priceFormatted + '/' + productUnitToLabel(unit, this.locale)
		}

		if(region && this.regions.length > 1)
		{
			priceFormatted += price.region ? ` (${regionMapping[price.region]})` : '';
		}

		
		return priceFormatted
	}

	formatPricesArray(prices: ProductPriceFragment[], productQuantity = 1, vat = false)
	{
		const regions = this.regions;
		prices = regions.length > 1 ? prices.filter(pr => pr.region) : prices;
		return prices.map(pr => this.formatPrice(pr, productQuantity, vat));
	}

	formatPrices(prices: ProductPriceFragment[], productQuantity = 1, vat = false)
	{
		const regions = this.regions;
		prices = regions.length > 1 ? prices.filter(pr => pr.region) : prices;
		return prices.map(pr => this.formatPrice(pr, productQuantity, vat)).join(', <br/>');
	}

	extractLocale<T extends { locale: { code: string } | undefined }>(
		locales: T[] | undefined,
	): T | null {
		return getLocale(locales, this.possibleLocales)
	}
}

export const makeLocalizationForLocale = async (locale: Locale, translator?: Translator) => {
	const possibleLocales = getPossibleLocales(locale)
	const regions = getRegionsForLocale(locale)
	const currency = getCurrencyByLocale(locale)
	translator = translator ?? await createTranslatorForLocale(locale);

	return new Localization(locale, possibleLocales, translator, currency, regions);
}

export const useLocalization = () => {
	const locale = useCurrentLocale()
	const possibleLocales = getPossibleLocales(locale)
	const translator = useTranslate()
	const currency = useCurrency()
	const regions = useRegions()
	return useMemo(
		() => new Localization(locale, possibleLocales, translator, currency, regions),
		[locale, possibleLocales, translator, currency, regions],
	)
}
