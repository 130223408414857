import * as React from 'react'

function SvgInstagram(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={35} height={35} {...props}>
			<g fill="currentColor" fillRule="nonzero">
				<path d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5zm17.5-9.333c-2.534 0-2.852.01-3.848.056-.993.046-1.671.203-2.265.434a4.57 4.57 0 00-1.653 1.076 4.582 4.582 0 00-1.078 1.653c-.23.594-.388 1.272-.433 2.265-.045.996-.056 1.314-.056 3.85 0 2.534.01 2.851.056 3.847.046.993.203 1.671.434 2.265a4.573 4.573 0 001.076 1.653 4.57 4.57 0 001.653 1.078c.594.23 1.272.388 2.266.433.995.045 1.313.057 3.848.057s2.852-.012 3.847-.057c.994-.045 1.673-.203 2.267-.433a4.567 4.567 0 001.652-1.078c.52-.518.838-1.04 1.077-1.653.23-.594.387-1.272.434-2.265.045-.996.056-1.313.056-3.848s-.011-2.853-.056-3.848c-.047-.994-.204-1.672-.434-2.266a4.58 4.58 0 00-1.077-1.653 4.562 4.562 0 00-1.653-1.076c-.595-.231-1.273-.388-2.267-.434-.996-.045-1.312-.056-3.848-.056h.003z" />
				<path d="M16.68 10.002h.82c2.443 0 2.732.01 3.697.053.892.04 1.376.19 1.699.315a2.83 2.83 0 011.05.684c.321.32.52.626.686 1.053.125.322.274.806.315 1.698.043.965.053 1.254.053 3.696s-.01 2.732-.053 3.696c-.041.893-.19 1.377-.315 1.699a2.83 2.83 0 01-.685 1.051 2.83 2.83 0 01-1.051.684c-.323.126-.807.275-1.699.316-.964.043-1.254.053-3.697.053s-2.732-.01-3.697-.053c-.892-.042-1.376-.19-1.699-.316a2.834 2.834 0 01-1.052-.684 2.836 2.836 0 01-.684-1.052c-.126-.322-.275-.806-.315-1.698-.044-.965-.053-1.255-.053-3.698s.009-2.732.053-3.696c.04-.892.19-1.377.315-1.7.165-.426.364-.731.684-1.051a2.84 2.84 0 011.052-.685c.323-.126.807-.275 1.7-.316.843-.038 1.17-.05 2.875-.051v.002zm5.704 1.52a1.098 1.098 0 100 2.196 1.098 1.098 0 000-2.197zM17.5 12.804a4.699 4.699 0 10.001 9.398 4.699 4.699 0 00-.001-9.398z" />
				<path d="M17.5 15a2.5 2.5 0 110 5 2.5 2.5 0 010-5z" />
			</g>
		</svg>
	)
}

export default SvgInstagram
