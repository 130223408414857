import Link, { LinkProps } from "next/link"
import { MouseEventHandler } from "react"
import { translate } from "react-range/lib/utils"
import { useLocalization } from "../../src/hooks/useLocaleFormatter"
import { usePageLink } from "../../src/hooks/usePageLink"
import { useTranslate } from "../../src/hooks/useTranslate"
import { FulltextHitFragment, FulltextRecordKind, SpecialPageType } from "../../src/model/fragments"
import { getLocale, Locale } from "../../src/model/locale"
import { getBlogArticleLink, getHelpQuestionLink, getPlaceLink, getProductCategoryLink, getProductLink } from "../../src/model/urls"


type FulltextHitGroup = {
	onHitClick?: MouseEventHandler<HTMLAnchorElement>
	hits: FulltextHitFragment[] | undefined
	title: React.ReactChild
} & (
	| {
			children: (item: FulltextHitFragment) => React.ReactChild
			linkFactory?: never
	  }
	| {
			linkFactory: (
				locale: Locale,
				id: string,
				url: string,
				item: FulltextHitFragment,
			) => LinkProps
			children?: never
	  }
)
const FulltextHitGroup: React.FC<FulltextHitGroup> = ({
	hits,
	title,
	onHitClick,
	...props
}) => {
	const localization = useLocalization()
	if (!hits || hits.length === 0) {
		return null
	}

	return (
		<li className="fulltextHitGroup">
			<h2 className="fulltextHitGroup-title">{title}</h2>
			<ul className="fulltextHitGroup-items">
				{hits.map((hit) => 
				{
					const locale = getLocale(hit.locales, localization.possibleLocales);

					if(!locale)
					{
						return;
					}

					return <li className="fulltextHitGroup-item" key={hit.id}>
						{props.linkFactory ? (
							<Link 
								{...props.linkFactory(
									localization.locale,
									hit.id,
									hit.link?.internalLink?.url || '/',
									hit,
								)}
							>
								<a className="fulltextHitGroup-item-link" onClick={onHitClick}>
									{ locale.title.substring(0,1).toLocaleUpperCase() + locale.title.substring(1) }
									{
										locale.subtitle && 
										<>
											<br/>
											<em>{locale.subtitle }</em>
										</>
									}									
								</a>
							</Link>
						) : (
							props.children(hit)
						)}
					</li>
				})}
			</ul>
		</li>
	)
}

const NoHit: React.FunctionComponent = () => {
	const helpPageLink = usePageLink(SpecialPageType.help)
	const translate = useTranslate()

	return (
		helpPageLink && (
			<li className="fulltextHitGroup">
				<h2 className="fulltextHitGroup-title">
					{translate('search-noMatch')}
				</h2>
				<ul className="fulltextHitGroup-items">
					<li className="fulltextHitGroup-item">
						<Link {...helpPageLink}>
							<a className="fulltextHitGroup-item-link">
								{translate('search-noMatch-help')}
							</a>
						</Link>
					</li>
				</ul>
			</li>
		)
	)
}

export type FulltextHitsProps = {
	hits: {[kind in FulltextRecordKind] : FulltextHitFragment[]}
	onHitClick?: MouseEventHandler<HTMLAnchorElement>
	total: number
};

export const FulltextHits: React.FC<FulltextHitsProps> = ({
	hits,
	total,
	onHitClick
}) => {
	const localization = useLocalization()

	return (
		<ul className="fulltextHits">
			{total === 0 ? (
				<NoHit />
			) : (
				<>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.productLine]}
						linkFactory={getProductCategoryLink}
						title={localization.translate('fulltext-' + FulltextRecordKind.productLine)}
					/>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.productCategory]}
						linkFactory={getProductCategoryLink}
						title={localization.translate('fulltext-' + FulltextRecordKind.productCategory)}
					/>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.productSystem]}
						linkFactory={getProductCategoryLink}
						title={localization.translate('fulltext-' + FulltextRecordKind.productSystem)}
					/>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.product]}
						linkFactory={getProductLink}
						title={localization.translate('fulltext-' + FulltextRecordKind.product)}
					/>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.productComposition]}
						linkFactory={(locale, id, url, item) =>
							getProductLink(locale, item.id, url)
						}
						title={localization.translate('fulltext-' + FulltextRecordKind.productComposition)}
					/>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.document]}
						title={localization.translate('fulltext-' + FulltextRecordKind.document)}
					>
						{(hit) => {
							const locale = getLocale(hit.locales, localization.possibleLocales);
							if(!locale)
							{
								return <></>;
							}

							return <a
								className="fulltextHitGroup-item-link"
								href={
									hit.link?.internalLink?.url ?? hit.link?.externalLink
								}
								target="_blank"
							>
								{ locale?.title && locale.title.substring(0,1).toLocaleUpperCase() + locale.title.substring(1) }
							</a>
						}}
					</FulltextHitGroup>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.place]}
						linkFactory={getPlaceLink}
						title={localization.translate('fulltext-' + FulltextRecordKind.place)}
					/>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.blogArticle]}
						linkFactory={getBlogArticleLink}
						title={localization.translate('fulltext-' + FulltextRecordKind.blogArticle)}
					/>
					<FulltextHitGroup
						onHitClick={onHitClick}
						hits={hits[FulltextRecordKind.helpQuestion]}
						linkFactory={getHelpQuestionLink}
						title={localization.translate('fulltext-' + FulltextRecordKind.helpQuestion)}
					/>
				</>
			)}
		</ul>
	)
}

