import * as React from 'react'

function SvgBook(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={81} height={63} {...props}>
			<path
				fill="currentColor"
				d="M61.167 50.462c-6.234 0-15.217 2.4-20.167 5.538V13.538C45.95 10.4 54.933 8 61.167 8c4.4 0 8.8.554 12.833 1.846v42.462c-4.033-1.293-8.433-1.846-12.833-1.846zM60.75 0C53.57 0 45.839 1.482 40.5 5.559 35.161 1.482 27.43 0 20.25 0S5.339 1.482 0 5.559V59.85c0 .926.92 1.853 1.84 1.853.37 0 .553-.185.921-.185 4.97-2.41 12.15-4.077 17.489-4.077 7.18 0 14.911 1.483 20.25 5.559 4.97-3.15 13.99-5.559 20.25-5.559 6.075 0 12.334 1.112 17.489 3.891.368.186.552.186.92.186.92 0 1.841-.927 1.841-1.853V5.559c-2.21-1.668-4.602-2.78-7.364-3.706C69.55.556 65.058 0 60.75 0z"
			/>
		</svg>
	)
}

export default SvgBook
