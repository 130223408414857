import React from 'react'
import { useLayoutData } from '../src/contexts/LayoutContext'
import { useTranslate } from '../src/hooks/useTranslate'
import { useCookiePreferences } from '../src/store/cookiePreferencesState'
import { FlexiLink } from './FlexiLink'

export const Footnote: React.FunctionComponent = () => {
	const { footerBottomMenu } = useLayoutData() || {}
	const translate = useTranslate()
	const [cookieState, setCookieState] = useCookiePreferences();

	return (
		<div className="footnote">
			<nav className="footnote-links">
				<div className="footnote-links-in">
					{footerBottomMenu &&
						footerBottomMenu.items.map((item, i) => (
							<React.Fragment key={i}>
								<FlexiLink {...(item.link as any)}>
									<a className="footnote-links-item">{item.caption}</a>
								</FlexiLink>{' '}
							</React.Fragment>
					))}

					<a className='footnote-links-item' href="" onClick={(e) => {e.preventDefault(); setCookieState({...cookieState, show: true, expand: true})}}>{translate('cookie-settings')}</a>
				</div>
			</nav>
			<div className="footnote-attribution">
				Spravuje{' '}
				<a
					href="https://www.digishock.cz/"
					target="_blank"
					rel="noopener noreferrer"
					className="footnote-attribution-link"
				>
					Digishock
				</a>
				{/* <span>, </span>
				<ReplacePlaceholders
					main={translate('footer-studio').toLocaleLowerCase()}
					placeholders={{
						studio: (
							<a
								href="https://www.mangoweb.cz/"
								target="_blank"
								rel="noopener noreferrer"
								className="footnote-attribution-link"
							>
								manGoweb
							</a>
						),
					}}
				/> */}
			</div>
		</div>
	)
}
