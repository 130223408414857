import { ProductUnit } from '../model/fragments'
import { Locale } from '../model/locale'
import { assertNever } from './assertNever'

export function productUnitToLabel(unit: ProductUnit, locale: Locale) {
	return unit === ProductUnit.sqm
		? '㎡'
		: unit === ProductUnit.item
		? 'ks'
		: unit === ProductUnit.ton
		? 't'
		: unit === ProductUnit.bag
		? locale === Locale.sk
			? 'vrece'
			: 'pytel'
		: assertNever(unit)
}
