import * as React from 'react'

function SvgFaq(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={63} height={72} {...props}>
			<path
				fill="currentColor"
				d="M39.095 31.433C36.575 33.935 35 35.99 35 41.133h-7v-1.714c0-3.77 1.575-7.198 4.095-9.7l4.34-4.319a6.634 6.634 0 002.065-4.833c0-3.77-3.15-6.855-7-6.855s-7 3.085-7 6.855h-7c0-7.575 6.265-13.71 14-13.71s14 6.135 14 13.71c0 3.017-1.26 5.759-3.255 7.712zM28 48h7v6.857h-7zM7 0C3.115 0 0 3.086 0 6.857v48c0 3.772 3.115 6.857 7 6.857h14L31.5 72 42 61.714h14c3.85 0 7-3.085 7-6.857v-48C63 3.086 59.85 0 56 0z"
			/>
		</svg>
	)
}

export default SvgFaq
