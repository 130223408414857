import { useEffect, useState } from 'react'

export const useDebounce = <T extends any>(value: T, time = 100): T => {
	const [debounced, setDebounced] = useState(value)
	useEffect(() => {
		const handle = setTimeout(() => {
			setDebounced(value)
		}, time)
		return () => clearTimeout(handle)
	}, [value, setDebounced, time])
	return debounced
}
