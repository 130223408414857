import Head from 'next/head'
import React from 'react'
import { SeoFragment } from '../src/model/fragments'

export interface SeoProps {
	seo: SeoFragment | undefined
}

export const Seo: React.FunctionComponent<SeoProps> = ({ seo }) => {
	const ogImage = seo?.ogImage || {
		previewUrl: '/og-image.jpg',
		intrinsicWidth: 1200,
		intrinsicHeight: 630,
	}
	return (
		<Head>
			<title>{seo?.title ? `${seo.title} | ` : ''}Presbeton</title>
			<meta name="description" content={seo?.description} />
			<meta property="og:title" content={seo?.ogTitle || seo?.title} />
			<meta property="og:image" content={ogImage.previewUrl} />
			{ogImage.intrinsicWidth && (
				<meta property="og:image:width" content={`${ogImage.intrinsicWidth}`} />
			)}
			{ogImage.intrinsicHeight && (
				<meta property="og:image:height" content={`${ogImage.intrinsicHeight}`} />
			)}
			<meta
				property="og:description"
				content={seo?.ogDescription || seo?.description}
			/>
		</Head>
	)
}
