import classNames from 'classnames'
import cn from 'classnames'
import React, { ChangeEvent, MutableRefObject } from 'react'

export interface CheckboxProps {
	label: React.ReactNode
	bland?: boolean
	onChange?: (checked: boolean) => void,
	control?: Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'className'>
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
	label,
	onChange,
	bland = false,
	control = {},
}) => {

	const [checked, setChecked] = React.useState<boolean>(control.checked ?? false);
	const handler = (e: ChangeEvent<HTMLInputElement>) => {
		setChecked(e.currentTarget.checked);
		
		if(onChange)
		{
			onChange(e.currentTarget.checked);
		}
		else if(control.onChange) 
		{
			// Legacy
			control.onChange(e);
		}
	};

	const ref = React.useRef() as MutableRefObject<HTMLInputElement>;
	return (
		<label className={cn('checkbox', bland && 'view-bland')}>
			<input ref={ref} type="checkbox" className="checkbox-control" onChange={handler} {...control} />
			<div className={classNames('checkbox-indicator', ref.current?.checked && 'checkbox-indicator--checked')} />	
			<div className="checkbox-label">{label}</div>
		</label>
	)
}
