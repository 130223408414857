import { useMemo } from 'react'
import { useCurrentLocale } from '../contexts/CurrentLanguageContext'
import { usePages } from '../contexts/PagesContext'
import { SpecialPageType } from '../model/fragments'
import { Locale } from '../model/locale'
import { getPageLink } from '../model/urls'

export const usePageLink = (
	specialType: SpecialPageType,
	forceLocale: null | Locale = null,
) => {
	const currentLocale = useCurrentLocale()
	const locale = forceLocale || currentLocale
	const pages = usePages()

	return useMemo(() => {
		if (specialType in pages) {
			const { id, url } = pages[specialType]
			return getPageLink(locale, id, url, specialType, forceLocale ? true : false)
		}
		return null
	}, [locale, pages, specialType])
}
