/* eslint-disable */
import React from 'react'
import { CookiePreferences, cookiePreferencesState, useCookiePreferences } from '../src/store/cookiePreferencesState';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import cn from 'classnames';
import { useTranslate } from '../src/hooks/useTranslate';
import { useCurrentLocale } from '../src/contexts/CurrentLanguageContext';
import { LinkType, SpecialPageType } from '../src/model/fragments';
import { usePageLink } from '../src/hooks/usePageLink';
import Link from 'next/link';

export interface CookieConsentProps {
}

export const CookieConsent: React.FunctionComponent<CookieConsentProps> = ({
}) => {

	const locale = useCurrentLocale();
	const translate = useTranslate();

	const [inputShow, setInputShow] = React.useState<boolean>(false);
	const [cookieState, setCookieState] = useCookiePreferences();
	const {preferences, show, expand} = cookieState;

	const all = {
		advertising: true,
		personalised: false,
		analytical: true
	} as CookiePreferences;
	const [inputPreferences, setInputPreferences] = React.useState(preferences ?? all);

	React.useEffect(() => {
		setInputPreferences(preferences ?? all);
		setInputShow(show);
	}, [cookieState]);

	const link = usePageLink(SpecialPageType.privacyNotice);

	return <>

		{
			cookieState.preferences &&
			<script dangerouslySetInnerHTML={{ __html: `
				gtag('consent', 'default', {
					ad_storage: '${cookieState.preferences?.advertising ? 'granted' : 'denied'}',
					ad_user_data: '${cookieState.preferences?.advertising ? 'granted' : 'denied'}',
					analytics_storage: '${cookieState.preferences?.analytical ? 'granted' : 'denied'}',
					functionality_storage: 'denied',
					personalization_storage: '${cookieState.preferences?.personalised ? 'granted' : 'denied'}',
					ad_personalization: '${cookieState.preferences?.advertising ? 'granted' : 'denied'}',
					security_storage: '${cookieState.preferences ? 'granted' : 'denied'}',
					wait_for_update: 500  });
			` }}></script> 
		}

		{<div className={cn('cookie-consent', inputShow ? 'cookie-consent--active' : '')}>
				<div className='cookie-consent--inner'>
					<h5>{expand ? translate('cookie-settings-header') : translate('cookie-notice-header')}</h5>

					<div>
					{!expand &&
						<p>
							{translate('cookie-notice-text')} {link && <Link {...link} ><a>{translate('cookies-link')}</a></Link>}.
						</p>
					}

					{expand && 
						<div>
							
							<p>
								{translate('cookie-settings-text')}
							</p>

							<div className='cookie-consent-inner-preference'>
								<h6>{translate('cookies-necessary')}</h6>
								<Checkbox control={{disabled: true, checked: true}} label={translate('cookies-necessary-text')} />
							</div>

							<div className='cookie-consent-inner-preference'>
								<h6>{translate('cookies-analytical')}</h6>
								<Checkbox control={{checked: inputPreferences.analytical, onClick: (e) => setInputPreferences({...inputPreferences, analytical: e.currentTarget.checked })}} label={translate('cookies-analytical-text')} />
							</div>

							<div className='cookie-consent-inner-preference'>
								<h6>{translate('cookies-advertising')}</h6>
								<Checkbox control={{checked: inputPreferences.advertising, onClick: (e) => setInputPreferences({...inputPreferences, advertising: e.currentTarget.checked })}} label={translate('cookies-advertising-text')} />
							</div>

							<p>
								{translate('cookie-settings-footnote')} {link && <Link {...link} ><a>{translate('cookies-link')}</a></Link>}.
							</p>

						</div>
					}
					</div>

					<div className='cookie-consent--inner--flex'>
						<a onClick={() => setCookieState({
							...cookieState,
							expand: !expand,
						})}>
							{expand ? translate('cookie-hide-settings-button') : translate('cookie-show-settings-button')}
						</a>
						<Button onClick={() => setCookieState({
							preferences: expand ? inputPreferences : all,
							show: false,
							expand: expand
						})}>{!expand ? translate('cookie-agree-all-header') : translate('cookie-agree-some-header')}</Button>
					</div>
				</div>
		</div>
		}
	</>;
}
/* eslint-enable */
