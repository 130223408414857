import * as React from 'react'

function SvgPinterest(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={35} height={35} {...props}>
			<path
				d="M0 17.5c0 7.413 4.612 13.746 11.121 16.296-.153-1.383-.29-3.508.062-5.022.317-1.365 2.052-8.697 2.052-8.697s-.523-1.049-.523-2.598c0-2.434 1.408-4.25 3.166-4.25 1.492 0 2.214 1.121 2.214 2.465 0 1.502-.956 3.746-1.45 5.827-.412 1.743.875 3.162 2.591 3.162 3.111 0 5.502-3.28 5.502-8.014 0-4.19-3.01-7.12-7.31-7.12-4.978 0-7.9 3.736-7.9 7.595 0 1.504.577 3.117 1.3 3.994a.528.528 0 01.123.503c-.133.552-.429 1.741-.486 1.983-.077.32-.255.39-.588.236-2.184-1.018-3.55-4.215-3.55-6.78 0-5.52 4.011-10.592 11.563-10.592 6.07 0 10.788 4.327 10.788 10.108 0 6.031-3.804 10.886-9.08 10.886-1.774 0-3.441-.923-4.012-2.01 0 0-.877 3.34-1.09 4.159-.395 1.52-1.463 3.425-2.174 4.587 1.636.509 3.375.782 5.181.782C27.165 35 35 27.165 35 17.5 35 7.833 27.165 0 17.5 0S0 7.833 0 17.5z"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</svg>
	)
}

export default SvgPinterest
