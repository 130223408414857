import { Currency } from '../model/currency'
import { assertNever } from './assertNever'
import { ProductPriceFragment, ProductPriceRangeFragment } from '../model/fragments'

export const multiplyPrice = <P extends (ProductPriceFragment | ProductPriceRangeFragment)>(
	price: P,
	quantity: number,
): P => {

	if((price as ProductPriceFragment).cents)
	{
		const p = price as ProductPriceFragment;
		return {
			cents: p.cents * quantity,
			currency: p.currency,
			region: p.region
		} as P
	}

	const p = price as ProductPriceRangeFragment;
	return {
		maxCents: p.maxCents * quantity,
		minCents: p.minCents * quantity,
		currency: p.currency,
		region: p.region
	} as P 
}

export const multiplyPrices = (
	prices: ProductPriceFragment[],
	quantity: number
) =>
{
	return prices.map(pr => multiplyPrice(pr, quantity))
}

export const multiplyPriceArrays = (
	prices: ProductPriceFragment[][]
) =>
{
	if(!prices.length)
	{
		return [];
	}

	const pr = JSON.parse(JSON.stringify(prices[0])) as ProductPriceFragment[];
	return pr.map((p : ProductPriceFragment, index) => ({
		...p,
		cents: prices.reduce((acc, pp) => acc * pp[index].cents, 1)
	}));
}

export const sumPriceArrays = (
	prices: ProductPriceFragment[][]
) =>
{
	if(!prices.length)
	{
		return [];
	}

	const pr = JSON.parse(JSON.stringify(prices[0])) as ProductPriceFragment[];
	return pr.map((p : ProductPriceFragment, index) => ({
		...p,
		cents: prices.reduce((acc, pp) => acc + (pp[index]?.cents ?? 0), 0)
	}));
}

export function formatPrice(
	{ cents, currency, region }: ProductPriceFragment,
	productQuantity = 1,
	vatMultiplier = 1,
): string {
	let result = ((productQuantity * vatMultiplier * cents) / 100).toFixed(2)
	result = result.replace('.', ',')

	// Dělá mezery mezi tisíci, milióny, …
	const [whole, fraction] = result.split(',')
	result = `${whole
		.split('')
		.reduce(
			(result, current, index) =>
				`${result}${(whole.length - index) % 3 === 0 ? ' ' : ''}${current}`,
			'',
		)},${fraction}`.trim()

	if (result.endsWith(',00')) {
		result = result.substr(0, result.length - 3)
	}

	let pr = null;
	switch (currency) {
		case Currency.CZK:
			pr = `${result} Kč`;
			break;
		case Currency.EUR:
			pr = `${result} €`;
			break;
		default:
			return assertNever(currency)
	}

	// if(currency === Currency.EUR && region)
	// {
	// 	pr += ` (${regionMapping[region]})`;
	// }

	return pr;
}
