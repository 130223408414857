import React from 'react'

type FullscreenContextProps = {
	isFullscreen: boolean,
	setIsFullscreen: (isFullscreen: boolean) => void
}

const defaultValue = {
	isFullscreen: false,
	setIsFullscreen: () => {}
}

const FullscreenContext = React.createContext<FullscreenContextProps>(defaultValue)

export const FullscreenContextProvider: React.FunctionComponent<{
	data?: FullscreenContextProps
}> = ({ children, data = defaultValue }) => (
	<FullscreenContext.Provider value={data!}>{children}</FullscreenContext.Provider>
)

export const useFullscreen = () => React.useContext(FullscreenContext)
