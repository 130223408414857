import React from 'react'
import { TranslationDomainFragment } from '../model/fragments'

interface AppTranslationsStateProps {
	primaryValues: Values
	fallbackValues: Values
}

interface Values {
	[key: string]: string
}

const defaultValue: AppTranslationsStateProps = {
	primaryValues: {},
	fallbackValues: {},
}

export const TranslationsContext = React.createContext(defaultValue)

export const translationDomainToPrimaryAndFallbackValues = (domain?: TranslationDomainFragment) => {
	const toKeyValue = (
		values: Array<{
			key:
				| {
						identifier: string
				  }
				| undefined
			value: string
		}>,
	) =>
		Object.fromEntries(
			values.map((value) => [value.key?.identifier || '', value.value]),
		)
	const primaryValues = toKeyValue(domain?.cataloguesByIdentifier?.values || [])
	const fallbackValues = toKeyValue(
		domain?.cataloguesByIdentifier?.fallback?.values || [],
	)

	return { primaryValues, fallbackValues };
}

export const TranslationsContextProvider: React.FunctionComponent<{
	data: TranslationDomainFragment | undefined
}> = ({ children, data }) => {
	return (
		<TranslationsContext.Provider value={translationDomainToPrimaryAndFallbackValues(data)}>
			{children}
		</TranslationsContext.Provider>
	)
}
