import React from 'react'

export function useOnAnyClick(
	callback: () => void,
	enabled: boolean = true,
	ignoredContainer: HTMLElement | null = null,
) {
	React.useEffect(() => {
		const onClick = (event: MouseEvent) => {
			if (
				!(event.target instanceof Element) ||
				!ignoredContainer ||
				!ignoredContainer.contains(event.target)
			) {
				callback()
			}
		}
		if (enabled) {
			document.addEventListener('click', onClick)
			return () => document.removeEventListener('click', onClick)
		}
	}, [callback, enabled, ignoredContainer])
}
