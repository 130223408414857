import Head from 'next/head'
import React from 'react'
import { Locale } from '../src/model/locale'
import { useCookiePreferences } from '../src/store/cookiePreferencesState'
import { assertNever } from '../src/utils/assertNever'

export interface GoogleAnalyticsProps {
	locale: Locale
}
/* eslint-disable */
export const GoogleAnalytics: React.FunctionComponent<GoogleAnalyticsProps> = ({
	locale,
}) => {

	const [cookieState] = useCookiePreferences();
	const trackingCodeId =
		locale === Locale.cs
			? 'GTM-539Q22J'
		: locale === Locale.sk
			? 'GTM-N5MNPTQ'
		: assertNever(locale)

	return (
		<Head>
			<script
				dangerouslySetInnerHTML={{
					__html: `
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${trackingCodeId}');
				`,
				}} 
			/>
			<script
				async
				src={`https://www.googletagmanager.com/gtag/js?id=${trackingCodeId}`}
			/>

			<script dangerouslySetInnerHTML={{ __html: `
				window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}
				gtag('consent', 'default', {
					ad_storage: '${cookieState.preferences?.advertising ? 'granted' : 'denied'}',
					ad_user_data: '${cookieState.preferences?.advertising ? 'granted' : 'denied'}',
					analytics_storage: '${cookieState.preferences?.analytical ? 'granted' : 'denied'}',
					functionality_storage: 'denied',
					personalization_storage: '${cookieState.preferences?.personalised ? 'granted' : 'denied'}',
					ad_personalization: '${cookieState.preferences?.advertising ? 'granted' : 'denied'}',
					security_storage: '${cookieState.preferences ? 'granted' : 'denied'}',
					wait_for_update: 500  });
				gtag('set', 'url_passthrough', true);
				gtag('js', new Date());  
				gtag('config', '${trackingCodeId}', {
					page_path: window.location.pathname,
				});

			` }} /> 
				
		</Head>
	)
}

/* eslint-enable */
