import { useRouter } from 'next/router'
import React from 'react'

export function useOnNavigation(callback: () => void) {
	const router = useRouter()
	const { asPath } = router
	const oldAsPath = React.useRef(asPath)

	React.useEffect(() => {
		if (oldAsPath.current !== asPath) {
			oldAsPath.current = asPath
			callback()
		}
	}, [callback, asPath])
}
