import * as React from 'react'

function SvgSlovakia(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
			<path fill="#f0f0f0" d="M0 85.337h512v341.326H0z" />
			<path fill="#0052b4" d="M0 196.641h512v118.717H0z" />
			<path fill="#d80027" d="M0 315.359h512v111.304H0z" />
			<path
				d="M129.468 181.799v85.136c0 48.429 63.267 63.267 63.267 63.267S256 315.362 256 266.935v-85.136H129.468z"
				fill="#f0f0f0"
			/>
			<path
				d="M146.126 184.294v81.941c0 5.472 1.215 10.64 3.623 15.485h85.97c2.408-4.844 3.623-10.012 3.623-15.485v-81.941h-93.216z"
				fill="#d80027"
			/>
			<path
				fill="#f0f0f0"
				d="M221.301 241.427h-21.425v-14.283h14.284v-14.283h-14.284v-14.284h-14.283v14.284h-14.282v14.283h14.282v14.283h-21.426v14.284h21.426v14.283h14.283v-14.283h21.425z"
			/>
			<path
				d="M169.232 301.658c9.204 5.783 18.66 9.143 23.502 10.636 4.842-1.494 14.298-4.852 23.502-10.636 9.282-5.833 15.79-12.506 19.484-19.939a24.878 24.878 0 00-14.418-4.583c-1.956 0-3.856.232-5.682.657-3.871-8.796-12.658-14.94-22.884-14.94-10.227 0-19.013 6.144-22.884 14.94a25.048 25.048 0 00-5.682-.657 24.88 24.88 0 00-14.418 4.583c3.691 7.433 10.198 14.106 19.48 19.939z"
				fill="#0052b4"
			/>
		</svg>
	)
}

export default SvgSlovakia
