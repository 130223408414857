import * as React from 'react'

function SvgCzechia(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
			<path fill="#0052b4" d="M0 85.337h512v341.326H0z" />
			<path fill="#d80027" d="M512 256v170.663H0L215.185 256z" />
			<path fill="#f0f0f0" d="M512 85.337V256H215.185L0 85.337z" />
		</svg>
	)
}

export default SvgCzechia
