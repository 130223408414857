import { assertNever } from '../utils/assertNever'
import { getConfig } from '../utils/config';


export enum Locale {
	cs = 'cs',
	sk = 'sk',
}

export const DEFAULT_LOCALE = Locale.cs
export const ALL_LOCALES = Object.values(Locale)

export const getLocalisedUrlHost = (locale: string) =>
{
	const val = process.env['WEB_URL_' + locale.toUpperCase()] ?? getConfig('WEB_URL_' + locale.toUpperCase())['WEB_URL_' + locale.toUpperCase()];
	if(!val)
	{
		throw new Error('Unable to determine localised url host.');
	}

	return val;

};

export const getLocaleUrlPrefix = (locale: string) => {

	// Must support both custom domains (no prefix) and locale prefixing
	const host = getLocalisedUrlHost(locale);
	const url = new URL(host);

	let path = url.pathname;
	if(path.endsWith('/'))
	{
		path = path.slice(0, -1);
	}

	return path;
};

export const getPossibleLocales = (locale: Locale): Locale[] => {
	locale = locale || DEFAULT_LOCALE
	switch (locale) {
		case Locale.cs:
			return [Locale.cs]
		case Locale.sk:
			return [Locale.sk, Locale.cs]
		default:
			return assertNever(locale)
	}
}

export const getLocale = <T extends { locale: { code: string } | undefined }>(
	locales: T[] | undefined,
	possibleLocales: Locale[],
): T | null => {
	for (const localeCode of possibleLocales) {
		for (const locale of locales || []) {
			if (locale?.locale?.code === localeCode) {
				return locale
			}
		}
	}
	return null
}
