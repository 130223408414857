import { atom, AtomEffect, DefaultValue, useRecoilState } from 'recoil'

const localStorageEffect: <T>(key: string) => AtomEffect<T> = (
	key: string
  ) => ({ setSelf, onSet }) => {

	if(!('localStorage' in globalThis))
	{
		return;
	}

	const savedValue = localStorage!.getItem(key);
	if (savedValue != null) {	
	  setSelf(JSON.parse(savedValue));
	}
  
	onSet((newValue) => {
	  if (newValue instanceof DefaultValue) {
		localStorage!.removeItem(key);
	  } else {
		localStorage!.setItem(key, JSON.stringify(newValue));
	  }
	});
  };
  

export const cookiePreferencesState = atom<CookiePreferencesState>({
	key: 'cookie-preferences',
	default: {
		preferences: null as CookiePreferences | null,
		show: true,
		expand: false
	},
	effects: [
	  localStorageEffect('cookie-preferences')
	]
})

export type CookiePreferencesState = {

	preferences: CookiePreferences | null,
	show: boolean
	expand: boolean
}

export type CookiePreferences = {
	personalised: boolean,
	advertising: boolean,
	analytical: boolean
};

export const useCookiePreferences = () => {
	return useRecoilState(cookiePreferencesState)
}

 