import cn from 'classnames'
import { useRevealHidingHeader } from 'hiding-header-react'
import Link from 'next/link'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { SpecialPageType } from '../src/model/fragments'
import { usePageLink } from '../src/hooks/usePageLink'
import { useIsLinkCurrent } from '../src/model/urls'
import { shoppingListState } from '../src/store/shoppingListState'
import SvgShoppingList from './icons/SvgShoppingList'
import dynamic from 'next/dynamic'

const sum = (array: number[]) => array.reduce((a, b) => a + b, 0)

const ShoppingListLinkSSR: React.FunctionComponent = (props) => {
	const { children } = props
	const shoppingListLink = usePageLink(SpecialPageType.shoppingList)
	const shoppingListInquiryLink = usePageLink(
		SpecialPageType.shoppingListInquiry,
	)
	const isShoppingListCurrent = useIsLinkCurrent(shoppingListLink)
	const isShoppingListInquiryCurrent = useIsLinkCurrent(shoppingListInquiryLink)
	const isActive = isShoppingListCurrent || isShoppingListInquiryCurrent
	const shoppingList = useRecoilValue(shoppingListState)
	const count =
		Object.keys(shoppingList.variants).length +
		Object.keys(shoppingList.compositions).length

	const unitCount =
		sum(Object.values(shoppingList.variants)) +
		sum(
			Object.values(shoppingList.compositions).flatMap((item) =>
				Object.values(item),
			),
		)
	const [previousUnitCount, setPreviousUnitCount] = React.useState(unitCount)

	const revealHeader = useRevealHidingHeader()
	React.useEffect(() => {
		if (unitCount > previousUnitCount) {
			revealHeader()
		}
		setPreviousUnitCount(unitCount)
	}, [unitCount, revealHeader, previousUnitCount])

	if (!shoppingListLink) {
		return null
	}

	return (
		<Link {...shoppingListLink}>
			<a className={cn('shoppingListLink', isActive && 'is-active')}>
				<div className="shoppingListLink-icon">
						{count > 0 && (
							<span className="shoppingListLink-indicator">
								<span className="shoppingListLink-indicator-in">{count}</span>
							</span>
						)}
					<SvgShoppingList />
				</div>
				<div className="shoppingListLink-label">{children}</div>
			</a>
		</Link>
	)
}

export const ShoppingListLink = dynamic(async () => ShoppingListLinkSSR, { ssr: false })
