import React, { useCallback } from 'react'
import { translationDomainToPrimaryAndFallbackValues, TranslationsContext } from '../contexts/TranslationsContext'
import { TranslationDomainFragment } from '../model/fragments';
import { Locale } from '../model/locale'
import { axiosGet } from '../utils/axiosWrapper';

export type Translator = (key: string) => string

export const createTranslatorForLocale = async (locale: Locale) : Promise<Translator> => {

	const {
		getTranslationDomain: domain,
	} = await axiosGet('/layout/translations', {locale: locale}) as {
		getTranslationDomain: TranslationDomainFragment
	};

	const appTranslations = translationDomainToPrimaryAndFallbackValues(domain);

	return (key: string): string => {
		return (
			appTranslations.primaryValues[key] ||
			appTranslations.fallbackValues[key] ||
			key
		).replaceAll('&nbsp;', '\u00A0')
	};
};

export const useTranslate = (): Translator => {
	const appTranslations = React.useContext(TranslationsContext)
	
	return useCallback(
		(key: string): string => {
			//console.log(key + ' => ' + appTranslations.primaryValues[key]);
			return (
				appTranslations.primaryValues[key] ||
				appTranslations.fallbackValues[key] ||
				key
			).replaceAll('&nbsp;', '\u00A0')
		},
		[appTranslations],
	)
}
