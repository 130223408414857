import React from 'react'
import { DEFAULT_LOCALE, Locale } from '../model/locale'

const CurrentLocaleContext = React.createContext(DEFAULT_LOCALE)

export const CurrentLocaleContextProvider: React.FunctionComponent<{
	currentLocale: Locale
}> = ({ currentLocale, children }) => (
	<CurrentLocaleContext.Provider value={currentLocale}>
		{children}
	</CurrentLocaleContext.Provider>
)

export const useCurrentLocale = (): Locale =>
	React.useContext(CurrentLocaleContext)
