import cn from 'classnames'
import { usePauseHidingHeader, useRunHidingHeader } from 'hiding-header-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useLayoutData } from '../src/contexts/LayoutContext'
import { useOnNavigation } from '../src/hooks/useOnNavigation'
import { usePageLink } from '../src/hooks/usePageLink'
import { useTranslate } from '../src/hooks/useTranslate'
import { SpecialPageType } from '../src/model/fragments'
import { pathnames } from '../src/model/urls'
import { subMenuArrowOffsetState } from '../src/store/subMenuArrowOffsetState'
import { subMenuDetailOpenState } from '../src/store/subMenuDetailOpenState'
import { subMenuMobileOpenState } from '../src/store/subMenuMobileOpenState'
import { isSpecialKeyPressed } from '../src/utils/isSpecialKeyPressed'
import { ConditionalWrap } from './ConditionalWrap'
import { FlexiLink } from './FlexiLink'
import { HamburgerButton } from './HamburgerButton'
import SvgLogoFull from './icons/SvgLogoFull'
import { LanguagePicker } from './LanguagePicker'
import { NavSearch } from './search/NavSearch'
import { ShoppingListLink } from './ShoppingListLink'
import { SubMenuDesktop } from './SubMenuDesktop'
import { SubMenuMobile } from './SubMenuMobile'

export const Header: React.FunctionComponent = () => {
	const { headerRightMenu, headerMainMenu } = useLayoutData() || {}
	const homepageLink = usePageLink(SpecialPageType.homepage)
	const shoppingListLink = usePageLink(SpecialPageType.shoppingList)
	const router = useRouter()
	const translate = useTranslate()
	
	const initialSubMenuIndex: number | null = React.useMemo(
		() =>
			(() => {
				const index = (headerMainMenu?.items || []).findIndex(
					(item) => item.productNavigation?.items?.length,
				)
				return index === -1 ? null : index
			})(),
		[headerMainMenu],
	)
	const openSubMenu =
		router.pathname === homepageLink?.href.pathname ||
		router.pathname === pathnames.product ||
		router.pathname === pathnames.productCategory
	const expectedSubMenuState = openSubMenu ? initialSubMenuIndex : null

	const [subMenuMobileOpen, setSubMenuMobileOpen] = useRecoilState(
		subMenuMobileOpenState,
	)

	const [subMenu, setSubMenu] = React.useState(expectedSubMenuState)
	useOnNavigation(() => {
		setSubMenu(expectedSubMenuState)
	})

	const [isSubMenuDetailOpen, setIsSubMenuDetailOpen] = useRecoilState(
		subMenuDetailOpenState,
	)
	React.useEffect(() => {
		setIsSubMenuDetailOpen(false)
	}, [setIsSubMenuDetailOpen, subMenu])

	const subMenuListArrowOffsetSet = useSetRecoilState(subMenuArrowOffsetState)

	const runHidingHeader = useRunHidingHeader()
	const pauseHidingHeader = usePauseHidingHeader()
	React.useEffect(() => {
		if (isSubMenuDetailOpen || subMenuMobileOpen) {
			pauseHidingHeader()
		} else {
			runHidingHeader()
		}
	}, [
		isSubMenuDetailOpen,
		subMenuMobileOpen,
		runHidingHeader,
		pauseHidingHeader,
	])
	
	const primaryItems = headerMainMenu?.items || []
	const rightMenuItems = headerRightMenu?.items || []
	return (
		<header
			className={cn('header', subMenuMobileOpen && 'is-mobileSubMenuOpen')}
		>
			<div className="header-in">
				<div className="header-content">
					<h1 className="header-logo">
						<ConditionalWrap
							condition={Boolean(homepageLink)}
							wrap={(children) => (
								<>
									{homepageLink && (
										<Link {...homepageLink}>
											<a
												className="header-logo-link"
												aria-label="Presbeton domovská stránka"
											>
												{children}
											</a>
										</Link>
									)}
								</>
							)}
						>
							<SvgLogoFull />
						</ConditionalWrap>
					</h1>
					<ul className="header-primary">
						{primaryItems.map((item, i) => (
							<li className="header-primary-item" key={item.id}>
								<FlexiLink {...(item.link as any)}>
									<a
										className={cn(
											'header-primary-link',
											router.asPath === item.link?.internalLink?.url &&
												'is-active',
											subMenu === i && 'is-open',
											item.productNavigation?.items && 'view-hasSubMenu',
										)}
										ref={(element) => {
											if (i === subMenu && element) {
												const { left, width } = element.getBoundingClientRect()
												subMenuListArrowOffsetSet(Math.floor(left + width / 2))
											}
										}}
										onClick={
											item.productNavigation?.items
												? (event) => {
														if (!isSpecialKeyPressed(event)) {
															event.preventDefault()
															setSubMenu(subMenu === i ? null : i)
														}
												  }
												: undefined
										}
									>
										{item.caption}
									</a>
								</FlexiLink>
							</li>
						))}
					</ul>
					<ul className="header-secondary">
						{rightMenuItems.map(
							(item) =>
								item.link && (
									<li className="header-secondary-item" key={item.id}>
										{item.link.internalLink?.page?.specialPage ===
										'shoppingList' ? (
											<ShoppingListLink>{item.caption}</ShoppingListLink>
										) : (
											<FlexiLink {...(item.link as any)}>
												<a
													className={cn(
														'header-secondary-link',
														router.asPath === item.link?.internalLink?.url &&
															'is-active',
													)}
												>
													{item.caption}
												</a>
											</FlexiLink>
										)}
									</li>
								),
						)}
						<li className="haeder-secondary-item">
							<NavSearch />
						</li> 
						<li className="header-secondary-item">
							<LanguagePicker />
						</li>
					</ul>
					<div className="header-mobileLanguagePicker">
						<LanguagePicker />
					</div>
					{shoppingListLink && (
						<div className="header-mobileShoppingList">
							<ShoppingListLink>Nákupní seznam</ShoppingListLink>
						</div>
					)}
					<div className="header-mobileSearch">
						<NavSearch />
					</div>
					<div className="header-hamburger">
						<HamburgerButton
							isOpen={subMenuMobileOpen}
							onClick={() => setSubMenuMobileOpen(!subMenuMobileOpen)}
						>
							{translate('header-menu-label')}
						</HamburgerButton>
					</div>
				</div>
			</div>
			<div className="header-subMenuList">
				<div className="header-subMenuList-in">
					{headerMainMenu && subMenu !== null && (
						<SubMenuDesktop
							items={
								headerMainMenu.items[subMenu].productNavigation?.items || []
							}
							key={subMenu}
						/>
					)}
				</div>
			</div>
			<div className="header-subMenuMobile">
				<SubMenuMobile
					primaryItems={primaryItems}
					rightMenuItems={rightMenuItems}
				/>
			</div>
		</header>
	)
}
