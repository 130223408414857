import Image from 'next/image'
import React from 'react'
import { ImageFragment } from '../src/model/fragments'

export enum PictureFit
{
	FitOriented = 'fit_orient',
	Fit = 'fit',
	Resize = 'resize',
	Widen = 'widen',
	Heighten = 'heighten'
}

export enum PictureEncode
{
	png = 'png',
	jpg = 'jpg',
	svg = 'svg'
}

export interface PictureProps {
	image: ImageFragment
	alt: string
	mode?: 'default' | 'fill' | 'contain' | 'cover'
	preload?: boolean
	decoding?: "async" | "sync",

	encode?: PictureEncode
	layout?: PictureFit
	width: number
	height: number
}

export const Picture: React.FunctionComponent<PictureProps> = (props) => {
	const { image, alt, width, height, mode = 'default', decoding, encode, layout = PictureFit.FitOriented } = props

	const src = React.useMemo(() => {
		if(!image)
		{
			return '';
		}

		if(image.external)
		{
			return image.previewUrl ?? image.internalUrl ?? '';
		}

		if(!image.internalUrl)
		{
			return '';
		}

		const e = encode ?? image.intrinsicEncode;
		const src = `${image.internalUrl}&w=${width}&h=${height}&l=${layout}&e=${e}`;
		return src;
	}, [image]);

	const sizes = React.useMemo(() => {

		if(width < 640)
		{
			return '100vw';
		}
		else 
		{
			return `(max-width: 640px) 100vw, ${width}px`;
		}

	}, [image]);

	// layout fill = nedavat width a height
	// layout cover = davat width a height
	const l = mode == 'fill' ? 'fill' : 'responsive';
	const objectFit = mode == 'contain' ? 'contain' : (mode == 'cover' ? 'cover' : undefined);
	return (
		<Image
			priority={props.preload ? true : false}
			src={src}
			sizes={sizes}
			decoding={decoding}
			layout={l}
			alt={alt}
			objectFit={objectFit}
			width={l == 'fill' ? undefined : width}
			height={l == 'fill' ? undefined : height}
			className="picture"
			quality={100}
			//imageSizes={[320, 640, 1280]}
		/>
	)
}
