import * as React from 'react'

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={35} height={35} {...props}>
			<path
				d="M0 17.04C0 7.63 7.63 0 17.04 0s17.04 7.63 17.04 17.04-7.63 17.04-17.04 17.04S0 26.45 0 17.04zm18.816 10.02v-9.272h2.56l.338-3.194h-2.898l.004-1.6c0-.832.08-1.279 1.276-1.279h1.6V8.52h-2.56c-3.074 0-4.156 1.55-4.156 4.156v1.918h-1.916v3.195h1.916v9.27h3.836z"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</svg>
	)
}

export default SvgFacebook
