export const isDefined = <V>(value: V | undefined): value is V =>
	value !== undefined && value !== null

export function assertDefined<V>(
	value: V | undefined | null,
): asserts value is V {
	if (value === undefined || value === null) {
		throw new Error('value should be defined')
	}
}
