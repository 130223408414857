import axios, { AxiosError } from "axios";
import getConfig from 'next/config'

export const axiosGet = async(path: string, query: object = {}) => {

    const { serverRuntimeConfig, _ } = getConfig()

    try 
    {
        const start = new Date();
        const uri = (serverRuntimeConfig.flexiInternalUrl) + '/api' + path;
        const response = await axios.get(uri, { 
            params: query,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Basic ' + serverRuntimeConfig.flexiToken
            }
        });

        console.log(`${start.toLocaleTimeString()} Axios GET ${uri} response time: ${(new Date().getTime()) - start.getTime()}ms`);
        return response.data;
    }
    catch(err)
    {
        if((err as AxiosError).response)
        {
            console.error((err as AxiosError).response!.data);
            throw new Error();
        }

        throw err;
    }

}

export const axiosPost = async(path: string, query: object = {}, body: ArrayBuffer | string | object = {}, onProgress : ((loaded: number, total: number) => void) | null = null) => {

    const { _, publicRuntimeConfig } = getConfig()

    try 
    {
        const response = await axios.post(publicRuntimeConfig.flexiExternalUrl + '/api' + path, body, { 
            params: query ?? {},
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + publicRuntimeConfig.flexiToken,
                'X-Requested-With': 'XmlHttpRequest'
            },
            onUploadProgress : (progressEvent : {
                loaded: number,
                total?: number
            }) => {

                if(onProgress != null)
                {
                    onProgress(progressEvent.loaded, progressEvent.total ?? 0);
                }
            }
        });

        return response.data;
    }
    catch(err)
    {
        if((err as AxiosError).response)
        {
            console.error((err as AxiosError).response!.data);
            throw new Error();
        }

        throw err;
    }
}


