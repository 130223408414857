import cn from 'classnames'
import React from 'react'
import { useRecoilValue } from 'recoil'
import {
	HeaderNavigationItemFragment,
	NavigationItemFragment,
	SpecialPageType,
} from '../src/model/fragments'
import { subMenuMobileOpenState } from '../src/store/subMenuMobileOpenState'
import { isSpecialKeyPressed } from '../src/utils/isSpecialKeyPressed'
import { Arrow } from './Arrow'
import { FlexiLink } from './FlexiLink'

export interface SubMenuMobileProps {
	primaryItems: HeaderNavigationItemFragment[]
	rightMenuItems: NavigationItemFragment[]
}

export const SubMenuMobile: React.FunctionComponent<SubMenuMobileProps> = ({
	primaryItems,
	rightMenuItems,
}) => {
	const isOpen = useRecoilValue(subMenuMobileOpenState)
	const [openSubMenu, setOpenSubMenu] = React.useState<null | string>(null)

	return (
		<div className={cn('subMenuMobile', isOpen && 'is-open')}>
			<ul className="subMenuMobile-main">
				{primaryItems.map((item) => {
					const hasSubItems = (item.productNavigation?.items || []).length > 0
					return (
						<li
							className={cn(
								'subMenuMobile-mainItem',
								hasSubItems && 'has-subItems',
								openSubMenu === item.id && 'is-open',
							)}
							key={item.id}
						>
							<FlexiLink {...(item.link as any)}>
								<a
									className="subMenuMobile-mainItem-link"
									onClick={
										hasSubItems
											? (event) => {
													if (isSpecialKeyPressed(event)) {
														return
													}
													event.preventDefault()
													setOpenSubMenu(
														openSubMenu === item.id ? null : item.id,
													)
											  }
											: undefined
									}
								>
									{item.caption} {!hasSubItems && <Arrow />}
								</a>
							</FlexiLink>
							{hasSubItems && (
								<ul className="subMenuMobile-subItems">
									{(item.productNavigation?.items || []).map((subItem, i) => (
										<li className="subMenuMobile-subItem" key={i}>
											<FlexiLink {...(subItem.link as any)}>
												<a className="subMenuMobile-subItem-link">
													{subItem.caption}
												</a>
											</FlexiLink>
										</li>
									))}
								</ul>
							)}
						</li>
					)
				})}

				{rightMenuItems
					.filter(
						(item) =>
						item.link?.internalLink?.page?.specialPage && 
						Object.values(SpecialPageType).includes(item.link?.internalLink?.page?.specialPage) && 
						item.link?.internalLink?.page?.specialPage !== 'shoppingList'
					)
					.map((item) => (
						<li className="subMenuMobile-mainItem" key={item.id}>
							<FlexiLink {...(item.link as any)}>
								<a className="subMenuMobile-mainItem-link">{item.caption} { <Arrow /> }</a>
							</FlexiLink>
						</li>
				))}
			</ul>
			<ul className="subMenuMobile-footer">
				{rightMenuItems
					.filter(
						(item) =>
							!item.link?.internalLink?.page?.specialPage ||
							!Object.values(SpecialPageType).includes(item.link?.internalLink?.page?.specialPage)
					)
					.map((item) => (
						<li className="subMenuMobile-footerItem" key={item.id}>
							<FlexiLink {...(item.link as any)}>
								<a className="subMenuMobile-footerItem-link">{item.caption}</a>
							</FlexiLink>
						</li>
					))}
			</ul>
		</div>
	)
}
