import cn from 'classnames'
import React from 'react'

export interface ArrowProps {
	direction?: 'left' | 'right'
}

export const Arrow: React.FunctionComponent<ArrowProps> = ({
	direction = 'right',
}) => <span className={cn('arrow', `view-direction-${direction}`)} />
