import React from 'react'
import { PageWithUrlFragment, SpecialPageType } from '../model/fragments'

interface PagesStateProps {
	[specialPage: string]: {
		id: string
		url: string
	}
}

const defaultValue: PagesStateProps = {}

const PagesContext = React.createContext<PagesStateProps>(defaultValue)

export const PagesContextProvider: React.FunctionComponent<{
	data: PageWithUrlFragment[]
}> = ({ children, data }) => { return (
	<PagesContext.Provider
		value={Object.fromEntries(
			data.map((page: any) => [
				Object.values(SpecialPageType).includes(page.specialPage) ? page.specialPage : null,
				{
					id: page.id,
					url: page.link.url,
				},
			]),
		)}
	>
		{children}
	</PagesContext.Provider>
)}

export const usePages = () => React.useContext(PagesContext)
