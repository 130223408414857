import * as React from 'react'

function SvgLogoFull(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="5.40625em" height="0.84375em" viewBox="0 0 173 27" {...props}>
			<path
				fill="currentColor"
				d="M77.979 18.383h5.62v3.436h3.434v-5.454l-4.892-.43-4.162-3.95V3.913L81.84.22h6.35l3.991 3.564V8.55h-5.15V5.158H83.6v5.239l4.849.429 4.205 3.993v8.202l-3.905 3.693h-6.908l-3.862-3.564zm-14.084 8.33V.22h11.732v4.938h-6.103v5.11h5.158v4.938h-5.158v6.613h6.103v4.895zm-16.919 0V.22h10.47l3.734 3.564v6.913l-3.004 2.705 3.476 2.534v10.778H56.03V15.936h-3.433v10.778zm9.06-15.672V5.158h-3.435v5.883zM30.23 26.714V.22h9.919l4.294 3.693v10.305l-4.208 3.65h-4.337v8.846zm8.588-13.74V5.157h-2.92v7.815z"
			/>
			<path
				fill="#299b49"
				d="M158.103 26.714V.22h5.196l4.595 14.9V.22h4.938v26.494h-4.938l-4.895-15.888v15.888zm-17.09-3.607V3.913L144.922.22h6.87l3.95 3.693v19.194l-3.95 3.607h-6.87zm5.626-1.288h3.425V5.158h-3.425zm-17.002 4.895V5.158h-3.953V.22h13.493v4.938h-3.91v21.556zm-17.307 0V.22h11.765v4.938h-6.097v5.11h5.153v4.938h-5.153v6.613h6.097v4.895zm-17.09 0V.22h10.477l3.736 3.564v6.913l-2.963 2.705 3.478 2.534v7.17l-3.907 3.608zm5.668-4.895h3.425v-5.883h-3.425zm3.425-10.778V5.158h-3.425v5.883zM.14 18.923L4.856 14.2l2.97.02 4.762 4.735-.018 3.015-4.712 4.692H4.88L.147 21.928zM.24 4.933L4.956.21l2.97.02 4.762 4.735-.018 3.015-4.712 4.692H4.98L.247 7.938zM13.96 18.923l4.716-4.723 2.97.02 4.762 4.735-.018 3.015-4.712 4.692H18.7l-4.734-4.734zM13.86 5.033L18.576.31l2.97.02 4.762 4.735-.018 3.015-4.712 4.692H18.6l-4.734-4.734zM13.166 9.01l4.547 4.546-4.547 4.547-4.546-4.547z"
			/>
		</svg>
	)
}

export default SvgLogoFull
