import * as React from 'react'

function SvgLinkedin(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={34} height={34} {...props}>
			<path
				d="M0 17C0 7.611 7.611 0 17 0s17 7.611 17 17-7.611 17-17 17S0 26.389 0 17zm12.014-2.92H8.16v11.576h3.853V14.08zm.253-3.58c-.025-1.136-.836-2-2.154-2s-2.18.864-2.18 2c0 1.11.836 2 2.13 2h.025c1.343 0 2.18-.89 2.18-2zm13.64 8.519c0-3.556-1.9-5.21-4.435-5.21-2.046 0-2.961 1.123-3.473 1.911v-1.64h-3.853c.051 1.087 0 11.576 0 11.576H18V19.19c0-.346.025-.69.127-.939.279-.69.913-1.406 1.977-1.406 1.395 0 1.952 1.061 1.952 2.617v6.193h3.852v-6.637z"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</svg>
	)
}

export default SvgLinkedin
