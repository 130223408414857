import React from 'react'
import { useLayoutData } from '../src/contexts/LayoutContext'
import { useTranslate } from '../src/hooks/useTranslate'
import { SocialLinkType } from '../src/model/fragments'
import { assertNever } from '../src/utils/assertNever'
import { FlexiLink } from './FlexiLink'
import { Footnote } from './Footnote'
import SvgFacebook from './icons/SvgFacebook'
import SvgInstagram from './icons/SvgInstagram'
import SvgLinkedin from './icons/SvgLinkedin'
import SvgLogoFull from './icons/SvgLogoFull'
import SvgPinterest from './icons/SvgPinterest'
import SvgTwitter from './icons/SvgTwitter'
import SvgYoutube from './icons/SvgYoutube'

interface FooterProps {
	copyright: React.ReactNode
}

export const Footer: React.FunctionComponent<FooterProps> = ({ copyright }) => {
	
	const { footerMenuColumns, socialLinks } = useLayoutData() || {}
	const translate = useTranslate();

	return (
		<footer className="footer">
			<div className="footer-in">
				<div className="footer-wrap">
					<div className="footer-item">
						<SvgLogoFull className="footer-logoTemporary" />
						<div className="footer-copyright">{copyright}</div>
					</div>

					{footerMenuColumns &&
						footerMenuColumns.map((column, i) => (
							<div className="footer-item" key={i}>
								<h5 className="footer-item-title">{column.title}</h5>
								{column.navigation &&
									column.navigation.items.map(
										(item, i) =>
											item.link && (
												<FlexiLink {...(item.link as any)} key={i}>
													<a className="footer-item-link">{item.caption}</a>
												</FlexiLink>
											),
									)}
							</div>
						))}

					{socialLinks && (
						<div className="footer-item">
							<h5 className="footer-item-title">{translate('follow-us')}</h5>
							{socialLinks.map((item, i) => (
								<a
									className="footer-item-icon"
									href={item.url}
									key={i}
									title={item.type}
									target="_blank"
									rel="noopener noreferrer"
								>
									{item.type === SocialLinkType.instagram ? (
										<SvgInstagram />
									) : item.type === SocialLinkType.facebook ? (
										<SvgFacebook />
									) : item.type === SocialLinkType.linkedin ? (
										<SvgLinkedin />
									) : item.type === SocialLinkType.youtube ? (
										<SvgYoutube />
									) : item.type === SocialLinkType.twitter ? (
										<SvgTwitter />
									) : item.type === SocialLinkType.pinterest ? (
										<SvgPinterest />
									) : (
										assertNever(item.type)
									)}
								</a>
							))}
						</div>
					)}
				</div>
				<Footnote />
			</div>
		</footer>
	)
}
