import * as React from 'react'

function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={35} height={35} viewBox="0 0 9.26 9.26" {...props}>
			<path
				d="M4.63 0a4.63 4.63 0 100 9.26 4.63 4.63 0 000-9.26zm1.714 6.518c-.811.055-2.618.055-3.428 0-.878-.06-.98-.49-.987-1.888.007-1.4.11-1.827.987-1.887.81-.056 2.617-.056 3.428 0 .878.06.98.49.987 1.887-.007 1.4-.11 1.828-.987 1.888zM3.858 3.727l1.897.902-1.897.905z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default SvgYoutube
