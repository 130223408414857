import * as React from 'react'

function SvgShoppingList(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width={25} height={20} {...props}>
			<path
				fill="currentColor"
				d="M6.338.668H24.5v2.674H6.338zm0 10.696V8.69H24.5v2.674zm0 8.02v-2.673H24.5v2.674zM2.446 16.27c.96 0 1.725.802 1.725 1.778s-.778 1.778-1.725 1.778c-.947 0-1.725-.802-1.725-1.778s.765-1.778 1.725-1.778zm0-16.27c1.077 0 1.946.896 1.946 2.005 0 1.11-.87 2.006-1.946 2.006C1.369 4.01.5 3.115.5 2.005.5.895 1.37 0 2.446 0zm0 8.021c1.077 0 1.946.896 1.946 2.006 0 1.11-.87 2.005-1.946 2.005-1.077 0-1.946-.896-1.946-2.005 0-1.11.87-2.006 1.946-2.006z"
			/>
		</svg>
	)
}

export default SvgShoppingList
