import cn from 'classnames'
import { HidingHeader } from 'hiding-header-react'
import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { useOnEscapeKey } from '../src/hooks/useOnEscapeKey'
import { useOnNavigation } from '../src/hooks/useOnNavigation'
import { subMenuDetailOpenState } from '../src/store/subMenuDetailOpenState'
import { subMenuMobileOpenState } from '../src/store/subMenuMobileOpenState'
import { Footer } from './Footer'
import { Header } from './Header'
import { PageTransitionIndicator } from './PageTransitionIndicator'
import { QuickLinks } from './QuickLinks'

export const Layout: React.FunctionComponent = (props) => {
	const [isSubMenuDetailOpen, setIsSubMenuDetailOpen] = useRecoilState(
		subMenuDetailOpenState,
	)
	const [isSubMenuMobileOpen, setIsSubMenuMobileOpen] = useRecoilState(
		subMenuMobileOpenState,
	)

	const closeBackground = React.useCallback(() => {
		setIsSubMenuDetailOpen(false)
		setIsSubMenuMobileOpen(false)
	}, [setIsSubMenuDetailOpen, setIsSubMenuMobileOpen])

	useOnNavigation(closeBackground)
	useOnEscapeKey(closeBackground, isSubMenuDetailOpen || isSubMenuMobileOpen)

	return (
		<div className="layout" id="__layout">
			 <PageTransitionIndicator /> 
			<ToastContainer
				position="bottom-right"
				transition={Slide}
				autoClose={15000}
			/>
			<HidingHeader>
				<Header />
			</HidingHeader>
			<button
				type="button"
				aria-label="Zavřít"
				className={cn(
					'layout-subMenuBackground',
					(isSubMenuDetailOpen || isSubMenuMobileOpen) && 'is-open',
				)}
				onClick={closeBackground}
			/>
			<div className="layout-main">{props.children}</div>
			<QuickLinks />
			<Footer
				copyright={
					<p>
						© Copyright {(new Date()).getFullYear()} PRESBETON Nova, s.r.o.,
						<br />
						ČSN EN ISO 9001:2009,
						<br />
						ČSN EN ISO 14001:2005
						<br />
					</p>
				}
			/>
		</div>
	)
}
