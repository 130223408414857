import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../src/hooks/useDebounce";
import { usePageLink } from "../../src/hooks/usePageLink";
import { useTranslate } from "../../src/hooks/useTranslate";
import { SpecialPageType } from "../../src/model/fragments";
import { getLinkWithQuery } from "../../src/model/urls";
import { Input } from "../Input";

export const FulltextSearchBox: React.FC<{queryString: string, onSubmit?: () => void, setQueryString: (q: string) => void, autofocus?: boolean}> = ({
	queryString,
	setQueryString,
	autofocus,
	onSubmit
}) => {
	const resultsLink = usePageLink(SpecialPageType.searchResults)
	const [query, setQuery] = useState(queryString || '')
	const translate = useTranslate();

	const debouncedQuery = useDebounce(query, 200)
	useEffect(() => {
		setQueryString(debouncedQuery)
	}, [debouncedQuery, setQueryString]);

	const router = useRouter()
	const submitCb = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault()
			if (!resultsLink) {
				return
			}
			const link = getLinkWithQuery(resultsLink, { q: query })
			router.push(link.href, link.as)
			
			if(onSubmit)
			{
				onSubmit();
			}
		},
		[query, resultsLink, router],
	)
	const ref = React.createRef<HTMLTextAreaElement & HTMLInputElement>();

	useEffect(() => {
		if(autofocus)
		{
			ref.current?.focus();
		}
	});

	useEffect(() => {
		if(!queryString && query)
		{
			setQuery('');
		}
	}, [queryString]);

	return (
		<form onSubmit={submitCb}>
			<Input
				ref={ref}
				name="query"
				label={translate('search') + ':'}
				type="search"
				autoComplete="off"
				value={query}
				submitStyle="striking"
				onChange={(event) => setQuery(event.currentTarget.value)}
			/>
		</form>
	)
}