import React from 'react';

interface ConditionalWrapProps {
    condition: boolean;
    wrap: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
}

// Original repository
// https://github.com/sizzyapp/conditional-wrap
export const ConditionalWrap: React.FunctionComponent<ConditionalWrapProps> =
({ condition, wrap, children}) => {
    return condition ? React.cloneElement(wrap(children)) : children
}